import { LoginCallback } from '@okta/okta-react';
import { Route, Routes } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { isV2 } from '../../utils/features';
import AcknowledgementsTable from '../analytics/AcknowledgementTable';
import ErrorPage from '../confirmation-error/ErrorPage';
import SuccessConfirmation from '../confirmation-error/SuccessConfirmation';
import UnauthorisedPage from '../confirmation-error/UnauthorisedPage';
import UnavailablePage from '../confirmation-error/UnavailablePage';
import FAQ from '../frequentlyAskedQuestions/FAQ';
import FAQEditForm from '../frequentlyAskedQuestions/FAQEditForm';
import FAQForm from '../frequentlyAskedQuestions/FAQForm';
import FrequentlyAskedQuestions from '../frequentlyAskedQuestions/FrequentlyAskedQuestions';
import History from '../history/History';
import Home from '../home/Home';
import NotFound from '../notFound/NotFound';
import { RequestRouteHandler } from '../request/RequestRouteHandler';
import RequestApprove from '../resolveRequest/RequestApprove';
import RequestDecline from '../resolveRequest/RequestDecline';
import RequestReminder from '../resolveRequest/RequestReminder';
import Layout from '../shared/Layout';
import Templates from '../templates/Templex';
import AcknowledgementsTableV1 from '../v1/analytics/AcknowledgementTable';
import HomeV1 from '../v1/home/Home';
import RequestFormV1 from '../v1/requestForm/RequestForm';
import Error from './Error';
import Loading from './Loading';
import RequiredAuth from './RequiredAuth';
import ScrollToTop from './ScrollToTop';

function RouteHandler() {
    return (
        <AuthContext.Consumer>
            {(authContext) => {
                return (
                    <Layout>
                        <ScrollToTop />
                        <Routes>
                            <Route path="*" element={<UnavailablePage />} />
                            <Route
                                path="/implicit/callback"
                                element={<LoginCallback errorComponent={Error} loadingElement={<Loading />} />}
                            />

                            {isV2(authContext?.user) && (
                                <>
                                    <Route path="/" element={<RequiredAuth />}>
                                        <Route index element={<Home />} />
                                    </Route>
                                    <Route path="/approve/:token?" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <RequestApprove
                                                    userInfo={authContext?.userInfo}
                                                    authContext={authContext?.authState}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/Requests/CloseRequest/:requestID?" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <RequestReminder
                                                    type="Close"
                                                    userInfo={authContext?.userInfo}
                                                    authContext={authContext?.authState}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/Requests/SaveReminder/:requestID?" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <RequestReminder
                                                    type="NoResponse"
                                                    userInfo={authContext?.userInfo}
                                                    authContext={authContext?.authState}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/decline/:token?" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <RequestDecline
                                                    userInfo={authContext?.userInfo}
                                                    authContext={authContext?.authState}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/success" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <SuccessConfirmation
                                                    userInfo={authContext?.userInfo}
                                                    authContext={authContext?.authState}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/error" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <ErrorPage
                                                    userInfo={authContext?.userInfo}
                                                    authContext={authContext?.authState}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/unavailable" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <UnavailablePage
                                                    userInfo={authContext?.userInfo}
                                                    authContext={authContext?.authState}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/unauthorised" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <UnauthorisedPage
                                                    userInfo={authContext?.userInfo}
                                                    authContext={authContext?.authState}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/Request" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <RequestRouteHandler
                                                    userInfo={authContext?.userInfo!}
                                                    authContext={authContext?.authState!}
                                                    user={authContext?.user!}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/request/:requestID?" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <RequestRouteHandler
                                                    userInfo={authContext?.userInfo!}
                                                    authContext={authContext?.authState!}
                                                    user={authContext?.user!}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/templates" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <Templates
                                                    userInfo={authContext?.userInfo}
                                                    authContext={authContext?.authState}
                                                    user={authContext?.user}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/frequentlyAskedQuestions" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={<FrequentlyAskedQuestions userInfo={authContext?.userInfo} />}
                                        />
                                    </Route>
                                    <Route path="/viewFrequentlyAskedQuestions" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <FAQ authContext={authContext?.authState} user={authContext?.user} />
                                            }
                                        />
                                    </Route>
                                    <Route path="/FAQForm" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <FAQForm
                                                    userInfo={authContext?.userInfo}
                                                    authContext={authContext?.authState}
                                                    user={authContext?.user}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/FAQForm" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <FAQForm
                                                    userInfo={authContext?.userInfo}
                                                    authContext={authContext?.authState}
                                                    user={authContext?.user}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/FAQEditForm/:ID" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <FAQEditForm
                                                    userInfo={authContext?.userInfo}
                                                    authContext={authContext?.authState}
                                                    user={authContext?.user}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/viewAllAcknowledgments" element={<RequiredAuth />}>
                                        <Route index element={<AcknowledgementsTable />} />
                                    </Route>
                                    <Route path="/request/:requestID?/history" element={<RequiredAuth />}>
                                        <Route index element={<History authContext={authContext?.authState} />} />
                                    </Route>
                                </>
                            )}

                            {!isV2(authContext?.user) && (
                                <>
                                    <Route path="/" element={<RequiredAuth />}>
                                        <Route index element={<HomeV1 userInfo={authContext?.userInfo} />} />
                                    </Route>
                                    <Route path="/approve/:token?" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <RequestApprove
                                                    userInfo={authContext?.userInfo}
                                                    authContext={authContext?.authState}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/Requests/CloseRequest/:requestID?" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <RequestReminder
                                                    type="Close"
                                                    userInfo={authContext?.userInfo}
                                                    authContext={authContext?.authState}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/Requests/SaveReminder/:requestID?" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <RequestReminder
                                                    type="NoResponse"
                                                    userInfo={authContext?.userInfo}
                                                    authContext={authContext?.authState}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/decline/:token?" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <RequestDecline
                                                    userInfo={authContext?.userInfo}
                                                    authContext={authContext?.authState}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/success" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <SuccessConfirmation
                                                    userInfo={authContext?.userInfo}
                                                    authContext={authContext?.authState}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/error" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <ErrorPage
                                                    userInfo={authContext?.userInfo}
                                                    authContext={authContext?.authState}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/unavailable" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <UnavailablePage
                                                    userInfo={authContext?.userInfo}
                                                    authContext={authContext?.authState}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/unauthorised" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <UnauthorisedPage
                                                    userInfo={authContext?.userInfo}
                                                    authContext={authContext?.authState}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/Request" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <RequestFormV1
                                                    userInfo={authContext?.userInfo}
                                                    authContext={authContext?.authState}
                                                    user={authContext?.user}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/request/:requestID?" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <RequestFormV1
                                                    userInfo={authContext?.userInfo}
                                                    authContext={authContext?.authState}
                                                    user={authContext?.user}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/templates" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <Templates
                                                    userInfo={authContext?.userInfo}
                                                    authContext={authContext?.authState}
                                                    user={authContext?.user}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/frequentlyAskedQuestions" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={<FrequentlyAskedQuestions userInfo={authContext?.userInfo} />}
                                        />
                                    </Route>
                                    <Route path="/viewFrequentlyAskedQuestions" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <FAQ authContext={authContext?.authState} user={authContext?.user} />
                                            }
                                        />
                                    </Route>
                                    <Route path="/FAQForm" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <FAQForm
                                                    userInfo={authContext?.userInfo}
                                                    authContext={authContext?.authState}
                                                    user={authContext?.user}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/FAQForm" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <FAQForm
                                                    userInfo={authContext?.userInfo}
                                                    authContext={authContext?.authState}
                                                    user={authContext?.user}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/FAQEditForm/:ID" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <FAQEditForm
                                                    userInfo={authContext?.userInfo}
                                                    authContext={authContext?.authState}
                                                    user={authContext?.user}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/viewAllAcknowledgments" element={<RequiredAuth />}>
                                        <Route
                                            index
                                            element={
                                                <AcknowledgementsTableV1
                                                    authContext={authContext?.authState}
                                                    user={authContext?.user}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="/request/:requestID?/history" element={<RequiredAuth />}>
                                        <Route index element={<History authContext={authContext?.authState} />} />
                                    </Route>
                                </>
                            )}
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </Layout>
                );
            }}
        </AuthContext.Consumer>
    );
}

export default RouteHandler;
