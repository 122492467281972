import '@bcg-web/bcg-bootstrap/dist/bcg-bootstrap.min.css';
import BCGReactTable from '@bcg-web/bcg-react-table';
import '@bcg-web/bcg-react-table/dist/bcg-react-table.css';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AcknowledgementCount } from '../../Models/AcknowledgementCount';
import { AckFilter } from '../../Models/AcknowledgmentsFilter';
import { PaginatedAcknowledgements } from '../../Models/PaginatedAcknowledgements';
import { State } from '../../Models/State';
import resources from '../../resources.json';
import httpService from '../../services/httpService';
import Loading from '../routeHandler/Loading';
import SearchBar from '../search/Search';
import ViewportAdapter from '../shared/ViewportAdapter';
import styles from './AcknowledgementTable.module.css';
import Pagination from './Pagination';

export type ColumnSort = {
    id: string;
    desc: boolean;
};

export type SortingState = ColumnSort[];
const AcknowledgementsTable = () => {
    const [tableData, setTableData] = useState<PaginatedAcknowledgements>({
        TotalPages: 0,
        Acknowledgements: [],
        PageSize: 25,
        TotalRecords: 0,
        PageNumber: 1,
    });
    const [state, setState] = useState(State.LOADING);
    const [filter, setFilter] = useState<AckFilter>({ searchText: '' });
    const [currentPage, setCurrentPage] = useState(1);
    const [sorting, setSorting] = useState<SortingState>([{ id: 'LastAcknowledgedOn', desc: true }]);

    useEffect(() => {
        (async () => {
            setState(State.LOADING);
            await httpService
                .getAcknowledgmentCounts(filter.searchText, currentPage, sorting[0].id, sorting[0].desc)
                .then((response) => {
                    setTableData(response);
                    setState(State.OK);
                })
                .catch(() => {
                    setState(State.FAILED);
                });
        })();
    }, [filter, currentPage, sorting]);

    const handlePageNumberChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleSorting = (sorting: SortingState) => {
        if (sorting.length > 0) {
            setSorting(sorting);
        }
    };

    const changeSearchText = (text: string) => {
        setFilter({ searchText: text });
        setCurrentPage(1);
    };

    return (
        <>
            <div className="hero-sub-component">
                <div className="container-md container hero-sub-content">
                    <h1>{resources.dashboard.acknowledgementsGrid.headerTitle}</h1>
                    <p>{resources.dashboard.acknowledgementsGrid.headerTitleCaption}</p>
                </div>
            </div>
            <div className="container">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a href="/nrl">{resources.request.previousCrumb}</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        {resources.acknowledgement.breadcrumb}
                    </li>
                </ol>
                <div className="search-container">
                    <SearchBar action={changeSearchText}></SearchBar>
                </div>
            </div>
            <div>
                <ViewportAdapter
                    desktop={
                        <DesktopView
                            filter={filter}
                            sorting={sorting}
                            currentPage={currentPage}
                            tableData={tableData}
                            loading={state === State.LOADING}
                            onPageChange={handlePageNumberChange}
                            onSortingChange={(e) => handleSorting(e)}
                        />
                    }
                    mobile={
                        <MobileView
                            tableData={tableData}
                            loading={state === State.LOADING}
                            onPageChange={(currentPage) => handlePageNumberChange(currentPage)}
                            currentPage={currentPage}
                        ></MobileView>
                    }
                ></ViewportAdapter>
            </div>
        </>
    );
};

const CardItems = (props: { acknowledgements: AcknowledgementCount[] }) => {
    return (
        <div className={styles.cards}>
            {props.acknowledgements.map((acknowledgementCount: AcknowledgementCount, index) => (
                <div key={acknowledgementCount.Company} className={'card gridCard ' + styles.card}>
                    <div className={'card-body ' + styles.cardBody}>
                        <span style={{ marginBottom: '5px' }}>
                            <span className={'badge badge-BCGBlue badge-info ' + styles.acknowledgementCount}>
                                {acknowledgementCount.Count} {resources.dashboard.acknowledgementsGrid.title}
                            </span>
                        </span>
                        <div>
                            <small> {resources.dashboard.acknowledgementsGrid.legalEntity} : </small>
                            <span className="">{acknowledgementCount.Company}</span>
                        </div>

                        <p className="card-text">
                            <small> {resources.dashboard.acknowledgementsGrid.lastAcknowledgement} : </small>
                            {moment(acknowledgementCount.LastAcknowledgedOn).format('DD/MM/YYYY')}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );
};

function MobileView(props: {
    tableData: PaginatedAcknowledgements;
    currentPage: number;
    onPageChange: (pageNumber: number) => void;
    loading?: boolean;
}) {
    return (
        <>
            {props.loading && <Loading></Loading>}
            {!props.loading && (
                <div className="container-sm d-flex flex-column w-100 gap-1">
                    <CardItems acknowledgements={props.tableData.Acknowledgements} />
                    <div className={styles.paginationContainer}>
                        <Pagination
                            OnPageChange={(currentPage) => props.onPageChange(currentPage)}
                            TotalPages={props?.tableData.TotalPages}
                            CurrentPage={props.currentPage}
                        ></Pagination>
                    </div>
                    <div style={{ padding: '5px', marginBottom: '7px' }}>
                        <Link to="/">
                            <button className="btn btn-sm btn-primary newRequestButton">
                                {resources.dashboard.acknowledgementsGrid.back}
                            </button>
                        </Link>
                    </div>
                </div>
            )}
        </>
    );
}

function DesktopView(props: {
    tableData: PaginatedAcknowledgements;
    filter: AckFilter;
    currentPage: number;
    sorting: SortingState;
    onPageChange: (pageNumber: number) => void;
    onSortingChange: (sorting: SortingState) => void;
    loading: boolean;
}) {
    const [sorting, setSorting] = useState(props.sorting);

    useEffect(() => {
        props.onSortingChange(sorting);
    }, [sorting]);

    const columns = [
        {
            header: resources.dashboard.acknowledgementsGrid.legalEntity,
            accessorKey: 'Company',
        },
        {
            header: resources.dashboard.acknowledgementsGrid.lastAcknowledgementV2,
            accessorKey: 'LastAcknowledgedOn',
            cell: ({ cell }: any) => <span>{moment(cell.getValue()).format('DD/MM/YYYY')}</span>,
        },
        {
            header: resources.dashboard.acknowledgementsGrid.countV2,
            accessorKey: 'Count',
        },
    ];

    return (
        <div className="container">
            <div className="d-flex w-100 flex-column">
                <BCGReactTable
                    data={props.tableData?.Acknowledgements || []}
                    columns={columns}
                    initialState={{
                        columnVisibility: {
                            View: true,
                        },
                    }}
                    state={{
                        showSkeletons: props.loading,
                        sorting: props.sorting,
                    }}
                    enableMultiRowSelection={false}
                    manualSorting
                    onSortingChange={setSorting}
                    enableCompactTable={true}
                    enableRowSelection={true}
                    enableStickyHeader={true}
                    enableRowSelectionWithCheckbox={false}
                    enableGlobalFilter={true}
                    tableBodyRowProps={({ row }: any) => ({
                        onClick: row.getToggleSelectedHandler(),
                        style: {
                            cursor: 'pointer',
                        },
                    })}
                />
                <div className={styles.paginationContainer}>
                    <Pagination
                        TotalPages={props.tableData?.TotalPages}
                        OnPageChange={(currentPage) => props.onPageChange(currentPage)}
                        CurrentPage={props.currentPage}
                    ></Pagination>
                </div>
            </div>
        </div>
    );
}

export default AcknowledgementsTable;
