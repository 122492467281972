import { Pagination as ReactPagination } from '@bcg-web/bcg-react-bootstrap';
import { ScrollBarNext, ScrollBarPrevious } from '../Icons/Icons';
import UseIsMobile from '../shared/useIsMobile';

export interface PaginationProps {
    TotalPages: number;
    OnPageChange: (page: number) => void;
    CurrentPage: number;
}

function Pagination(props: Readonly<PaginationProps>) {
    const handleClick = (page: number) => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        props.OnPageChange(page);
    };
    const isMobile = UseIsMobile();
    const { CurrentPage, TotalPages } = props;

    const visibleItems = isMobile ? 3 : 10;
    const halfItems = Math.floor(visibleItems / 2);

    const startPage = Math.max(2, Math.min(CurrentPage - halfItems, (TotalPages ?? 0) - visibleItems + 2));
    const endPage = Math.min((TotalPages ?? 0) - 1, startPage + visibleItems - 1);

    const pageNumbers = Array.from({ length: Math.max(0, endPage - startPage + 1) }, (_, i) => startPage + i);

    return (
        <ReactPagination>
            <ReactPagination.Prev onClick={() => handleClick(CurrentPage - 1)} disabled={CurrentPage === 1}>
                <ScrollBarPrevious />
            </ReactPagination.Prev>

            <ReactPagination.Item
                className={CurrentPage === 1 ? 'page-item active' : 'page-item'}
                onClick={() => handleClick(1)}
            >
                {1}
            </ReactPagination.Item>

            {startPage > 2 && <ReactPagination.Ellipsis disabled />}
            {pageNumbers.map((page) => (
                <ReactPagination.Item
                    className={CurrentPage === page ? 'page-item active' : 'page-item'}
                    key={page}
                    onClick={() => handleClick(page)}
                >
                    {page}
                </ReactPagination.Item>
            ))}

            {endPage < (TotalPages ?? 0) - 1 && <ReactPagination.Ellipsis disabled />}
            {TotalPages > 1 && (
                <ReactPagination.Item
                    className={CurrentPage === TotalPages ? 'page-item active' : 'page-item'}
                    onClick={() => handleClick(TotalPages)}
                >
                    {TotalPages}
                </ReactPagination.Item>
            )}

            <ReactPagination.Next onClick={() => handleClick(CurrentPage + 1)} disabled={CurrentPage === TotalPages}>
                <ScrollBarNext />
            </ReactPagination.Next>
        </ReactPagination>
    );
}

export default Pagination;
